.custom-title-wrapper {
  position: relative;
  flex: 1;
}

.custom-label-input {
  width: 100%;
  color: gray;
  border: none;
  border-bottom: 2px solid rgb(111, 100, 100);
  flex: 1;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  position: relative;
}

.custom-label-check {
  position: absolute;
  right: 10px;
  bottom: 12px;
  background-image: url('../../assets/icons/check.svg');
  background-size: cover;
  width: 12px;
  height: 12px;
  cursor: pointer;

  &.select-custom {
    right: -20px;
  }
}

.circle-minus-svg {
  height: 20px;
  width: 20px;
  background-image: url('../../assets/icons/circle-minus-solid.svg');
  background-repeat: no-repeat;
  margin: 0 5px 5px 0;
}

.content-container {
  flex: 0 1 100%;
  min-height: 100vh;

  & .topbar {
    padding: 15px 5px;
  }

  & .public-subcontent {
    background-color: #0000b4;
    padding: 160px 160px 40px 40px;
    width: 760px;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 1000;

    & .header {
      font-size: rem(50px);
      font-weight: 300;
      color: $white;
      line-height: 1;
    }
  }

  & .page-container {
    & .header {
      font-size: 56px;
      margin-bottom: 60px;
      line-height: 1;
    }
  }

  & .public-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
  }
}

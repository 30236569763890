.asset-title-wrapper {
  display: flex;
  align-items: center;

  span {
    height: 18px;
  }
}

.preset-size-row {
  position: relative;

  &:hover {
    .preset-size-actions {
      opacity: 1;
    }
  }

  .preset-size-actions {
    position: absolute;
    right: 1rem;
    display: flex;
    justify-content: flex-end;
    width: 100px;
    opacity: 0;
  }
}

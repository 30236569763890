.custom-toggle {
  cursor: pointer;
  svg {
    color: $primary;
  }
  &:hover svg {
    color: #f7b500;
  }
  &.disabled {
    cursor: initial;
    svg {
      color: $gray-600;
    }
  }
}

.project-list-link {
  font-size: 14px;
  color: black;

  &:hover {
    background-color: #e5edff;
  }

  .project-link-name {
    color: $primary;
    font-weight: 900;
  }

  & td .avatar img {
    min-width: 60px;
    min-height: 60px;
  }
}

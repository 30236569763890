.wizard-step-info {
  display: flex;
  position: relative;
  transform: translateX(-15px);

  .subline {
    position: absolute;
    width: 250px;
    height: 1px;
    background-color: lightgray;
    top: 10px;
    left: 20px;
  }
  .wizard-step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;
    margin-right: 3rem;

    .wizard-step-number {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      aspect-ratio: 1;
      background-color: gray;
      border-radius: 50%;
      margin-bottom: 0.5rem;
      color: white;
      z-index: 2;

      &.active {
        color: white;
        background-color: #081ee0;
      }

      &.finished {
        background-color: black;
      }
    }
    .wizard-step-info-title {
      margin: 0;
      color: gray;
      text-transform: uppercase;

      &.active {
        color: #081ee0;
      }

      &.finished {
        color: black;
      }
    }
  }
}

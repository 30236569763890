.alert.alert-danger {
  border-radius: 4px;
  background-color: #fecec6;
  color: #000000;
  padding: 13px 20px;
}

.alert-danger-text {
  color: $danger;
}

@use "~ag-grid-community/styles" as ag;
@include ag.grid-styles();

div.ag-theme-alpine {
  font-family: Biennale, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  .ag-root-wrapper {
    border: 0;
  }
  .ag-cell-wrapper {
    align-items: center;
  }
  .ag-center-cols-clipper,
  .ag-center-cols-container {
    min-height: 300px !important;
  }

  .ag-header {
    border-bottom: solid 2px;
    border-bottom-color: $gray-200;
    background-color: $white;

    .ag-cell-label-container .ag-header-cell-text {
      font-weight: normal;
      text-transform: uppercase;
      font-size: 0.75rem;
    }
  }

  .ag-header .ag-header-cell-resize {
    border-left: solid 1px #babfc7;

    &:after {
      background: none;
    }
  }
  .ag-pinned-left-header .ag-header-cell-resize {
    border-left: 0;
  }

  .ag-row {
    border-color: $gray-200;

    .ag-cell {
      line-height: 60px;

      &.row-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: visible;
      }
    }

    &.ag-row-selected {
      background-color: #cbd0fd;
    }

    &.ag-row-hover {
      background-color: #f5f6ff;
    }
  }

  .ag-row-odd {
    background-color: $white;
  }

  .ag-details-row {
    padding: 0;
    margin: 0 30px;
    border-left: 2px solid $primary;

    .ag-row {
      background-color: $gray-200;

      &.ag-row-selected {
        background-color: #cbd0fd;
      }

      &.ag-row-hover {
        background-color: #f5f6ff;
      }
    }
  }

  &.banner {
    .ag-row-level-1 {
      padding: 10px 20px;
      margin: 0 30px;
      border-left: 2px solid $primary;

      &.ag-row-hover {
        background-color: white;
      }
    }
  }
  .ag-pinned-right-cols-container {
    border: 0;

    .ag-cell {
      border: 0 !important;
    }
  }
  .ag-pinned-right-header {
    border-left: 0;
  }

  .ag-pinned-right-header .ag-header-cell-resize {
    border-left: 0;
  }

  .ag-cell-inline-editing {
    height: 60px;
  }

  .ag-ltr .ag-pinned-right-header .ag-header-row::after {
    content: none;
  }
}

.share-table.ag-theme-alpine {
  .ag-header .ag-header-cell-resize {
    border-left: 0px !important;

    &:after {
      background: none;
    }
  }

  .ag-header-cell {
    padding-right: 0 !important;
  }
}

.inverted-ag-black {
  filter: brightness(0) saturate(100%) invert(8%) sepia(21%) saturate(397%) hue-rotate(152deg)
    brightness(98%) contrast(94%);
}

.submenu {
  &.nav {
    border-left: 1px solid rgba($gray-700, 0.2);

    & .nav-link {
      height: 40px;
      color: $gray-500;
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }

      &.active {
        border-left: 1px solid $primary;
        margin-left: -1px;
        color: $black;
      }

      &.sublink {
        padding-left: 30px;
      }
    }
  }
}

.briefing-menu {
  & .nav-link {
    cursor: pointer;
    color: $gray-600;
    padding-right: 50px;
    &.active {
      text-decoration: underline;
      color: black;
    }
  }
}

.sidebar {
  padding-top: 265px;
  background-color: #0000b4;
  flex: 1 0 340px;
  min-height: 100vh;
  z-index: 1000;
  & .nav {
    margin-left: 120px;
    border-left: 1px $gray-700 solid;
  }
  & .nav-link {
    font-size: rem(16px);
    color: $white;
    height: 49px;
    display: flex;
    align-items: center;
    padding: 0 19px;
    margin: 20px 0;

    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    &.active {
      color: $white;
      border-left: 1px $white solid;
    }
  }
}

.sidebar-private {
  background-color: #0000b4;
  flex: 1 0 70px;
  min-height: 100vh;
  padding: 20px 10px;
  min-width: 70px;
  & .sidebar-contant {
    position: fixed;
  }
  // margin-right: 60px;
  & .nav {
    margin-top: 20px;
    padding: 0;
    align-items: center;
  }
  & .logo {
    justify-content: flex-start;
    padding-bottom: 40px;
    & img {
      width: 50px;
    }
  }

  & .nav-link {
    padding: 0;
    color: $white;
    display: flex;
    vertical-align: top;
    margin-bottom: 40px;
    justify-content: flex-start;
    // padding-left: 20px;
    // justify-content: center;

    &.active {
      color: $white;
      & .icon {
        background-color: $info;
      }
      & .nav-item-label {
        color: $info;
      }
    }

    & .icon {
      width: 10px;
      height: 10px;
      background-color: $white;
      padding-left: 20px;
    }

    & .nav-item-label {
      display: none;
      // background-color: $white;
    }
  }

  &:hover {
    flex: 1 0 180px;
    z-index: 2000;
    min-width: 180px;

    & .nav {
      padding: 0;
      align-items: flex-start;
    }

    .logo {
      padding-left: 20px;
    }

    & .nav-item-label {
      display: block;
      background-color: transparent;
    }
    & .nav-link {
      padding-left: 20px;
      padding-right: 20px;
      justify-content: flex-start;
      & .icon {
        width: 10px;
        height: 10px;
        margin-right: 10px;
      }
    }
  }
}

.sidebar-private.large {
  background-color: #0000b4;
  flex: 1 0 320px;
  & .nav {
    padding: 0;
    padding-left: 20px;
  }
  & .logo {
    justify-content: flex-start;
    padding-left: 20px;
  }
  & .nav-link {
    padding: 0;
    color: $white;
    display: flex;
    vertical-align: top;
    margin-bottom: 25px;
    justify-content: flex-start;

    & .icon {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }

    & .nav-item-label {
      display: block;
      background-color: transparent;
    }
    &.active .nav-item-label {
      color: $info;
    }
  }
}

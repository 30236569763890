.template-text-green {
  color: $success;
  line-height: 0.8rem;
}

.template-text-gray {
  color: $gray-600;
}

.template-title {
  padding-top: 0.5rem;
  min-height: 3rem;
  max-width: fit-content;
  word-break: break-all;
}

.creative-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.template-list {
  display: flex;
  flex-wrap: wrap;
}

.template-item {
  display: flex;
  flex-direction: column;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
}

.template-section-title {
  display: flex;
  justify-content: space-between;
  width: 14rem;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
}

.show-more {
  display: flex;
  padding-top: 0.75rem;
  color: $gray-600;
}

.thumbnail {
  width: 10rem;
  height: 10rem;
}

.team {
  & .team-container {
    min-height: 500px;
    & .team-name {
      max-width: 25vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // & .team-name-text {
      //   max-width: 100%;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      // }
    }
  }
}

.team-info {
  & .team-image {
    border: 1px dashed $gray-200;
    min-height: 400px;
    min-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-200;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .nav {
    margin-bottom: 15px;
    & .nav-link {
      padding-bottom: 5px;
      &.active {
        border-bottom: 1px solid $black;
        color: $black;
      }
    }
  }
}

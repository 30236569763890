.wizard {
  min-height: 500px;
  &.lg {
    width: 50rem;
  }
  &.md {
    width: 30rem;
  }
  &.sm {
    width: 15rem;
  }

  .wizard-info-section {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;

    h4 {
      margin: 0 2rem 0 0;
    }

    button {
      padding: 5px 1rem;
      color: black;
      background-color: lightgray;
      border-radius: 1rem;
    }
  }

  .wizard-legend-and-actions-wrapper {
    display: flex;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0.75rem;
    border-bottom-left-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }

  &-step {
    padding: 2rem 2rem 2rem 0;
  }

  &-step-title {
    color: #0154ff;
  }

  & .prev-action-button {
    background-color: lightgray;
    color: #000;
    border-radius: 1rem;
    border: none;
    margin-right: 1rem;
    height: 30px;
    padding: 5px 1rem;
    align-self: center;
  }

  & .top-action-button {
    background-color: #0154ff;
    color: $white;
    border-radius: 1rem;
    border: none;
    height: 30px;
    padding: 5px 1rem;
    align-self: center;
  }
}

.side-modal {
  width: auto;
  height: 100%;
  right: 0;
  left: auto;
  & .modal-dialog {
    margin: 0;
    width: 800px;
    max-width: inherit;
    height: 100%;
    & .modal-content {
      min-height: 100%;
      & .modal-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        background-color: $black;

        & button {
          background-color: inherit;
          color: white;
          border: none;
          font-weight: 900;
        }
      }
    }
  }
}

.side-modal-body {
  overflow-y: auto !important;
}

.dco-edit-form-wrapper {
  min-height: 600px;
  margin-top: 40px;

  .dco-edit-status-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;

    p {
      margin: 0;
    }

    button {
      background-color: lightgray;
      color: #000;
      border-radius: 1rem;
      border: none;
      margin-right: 1rem;
      height: 30px;
      padding: 5px 1rem;
      margin-left: 2rem;
    }
  }

  .dco-info-tab-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
  }

  .download-manifest {
    position: absolute;
    top: 0;
    left: 70px;
  }

  .icon-copy-link {
    position: absolute;
    top: -5px;
    left: 50px;
    background-image: url('../../assets/icons/copylink.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }

  .input-group-underline {
    margin: 2rem 2rem 2rem 0;

    input {
      padding-left: 10px;
      border: none;
      border-bottom: 2px solid $primary;
      min-width: 300px;
    }
  }

  .dco-edit-creatives-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;

    h4 {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}

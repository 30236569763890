@import '_overwrite';
@import '~bootstrap/scss/bootstrap';

@import './layout.scss';
@import './sidebar.scss';
@import './preloaders.scss';
@import './navs.scss';
@import './project.scss';
@import './team.scss';
@import './dropzone.scss';
@import './imageUploader.scss';
@import './sideModal.scss';
@import './comments.scss';
@import './icon.scss';
@import './brand.scss';
@import './input.scss';
@import './alerts.scss';
@import './cutomToggle.scss';
@import './avatars.scss';
@import './fileAssetView.scss';
@import './buttons.scss';
@import './filePreview.scss';
@import './submenu.scss';
@import './tabmenu.scss';
@import './login.scss';
@import './signIn.scss';
@import './welcome.scss';
@import './FeedsTable/feedsTable';
@import './FeedsTable/viewFeed';
@import './FeedsTable/editFeed';
@import './templates.scss';
@import './wizard.scss';
@import './wizardStepInfo';
@import './dco.scss';
@import './dcotable.scss';
@import './DcoSetupForm/projectInfo';
@import './DcoSetupForm/adColumns';
@import './DcoSetupForm/creativeVariables';
@import './DcoSetupForm/uploadCreatives';
@import './DcoSetupForm/dcoEditSetup';
@import './DcoSetupForm/dcoEditForm';
@import './DcoSetupForm/uploadCreativesForm';
@import './DcoSetupForm/dcoCreativeSizeRow';
@import './DcoSetupForm/dcoCreativeSizeForm';
@import './AgGrid.scss';
@import 'components/switch';
@import 'components/loaderButton';
@import 'components/videoTemplates';
@import 'components/htmlTemplates';
@import './components/creativeForm';
@import './components/brandTeamList';
@import './components//asset_presets_group';

// pages
@import './pages/dashboard';
@import './pages/sharedPage';
@import './pages/privateSharePage';

.w-40 {
  width: 40%;
}

.tile {
  // display: inline-block;
  // padding: 10px;

  // &:first-of-type {
  // 	padding-left: 0;
  // }

  & .title {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  column-gap: 20px;
  row-gap: 20px;

  &.dashboard {
    & .tile:first-of-type {
      grid-column: 1 / -1;

      & img {
        width: 100%;
        height: 340px;
        object-fit: cover;
      }

      & .title {
        padding: 20px;
        background-color: $primary;
        color: white;
        margin-top: 0;
      }
    }
  }
}

.btn-hover:hover {
  color: gray;
}

.btn:focus,
.btn:active,
a:focus,
a:active,
.custom-select {
  outline: none !important;
  box-shadow: none !important;
}

* {
  -webkit-font-smoothing: antialiased;
  outline: none !important;
}

// :not(.image-crop, .preview) {
// }

.tableview {
  border-radius: 2px;
  background-color: $white;

  & .table thead th {
    border-bottom: none;

    &.sorting {
      cursor: pointer;
    }
  }

  & .table td {
    vertical-align: middle;
  }

  & .table th,
  .table td {
    padding: 1rem;
    border-top: unset;
  }

  & .tableview-head {
    padding: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;

    & .tableview-head-label {
      font-size: 1.3125rem;
      font-weight: 500;
      line-height: 1.16667em;
    }
  }

  & .tableview-content {
    padding: 0 24px;
    overflow-x: hidden;
  }

  & .tableview-foot {
    // height: 56px;
    display: flex;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.43) !important;
  z-index: 10000;
}

.bg-secondary-tr {
  background-color: #e5edff;
}

.dynamicVideoBugFix {
  -webkit-transform-style: preserve-3d;
}

.checkboxHidden {}

.checkboxHidden .checkbox-item {
  display: none;
}

.droplabel {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pretty.p-default input:checked~.state.p-custom-primary label:after,
.pretty.p-toggle .state.p-custom-primary label:after {
  background-color: #081ee0 !important;
}

.fade {
  z-index: 9999 !important;
}

.custom-map-modal {
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    -webkit-backdrop-filter: blur(8.7px);
    backdrop-filter: blur(8.7px);
    background: rgba(255, 255, 255, 0.86);
    height: 100vh;
    width: 100vw;
    min-height: 100vh;
    border-radius: 0;
  }
}

.custom-map-modal>.modal-dialog>.modal-content>.modal-body {
  padding: 0 !important;
}

.creative-dialog>.modal-dialog {
  width: 1200px;
}

@-moz-document url-prefix() {
  .custom-map-modal {
    .modal-content {
      background: rgba(255, 255, 255, 1);
    }
  }
}

.embededLinkSize {
  width: 90vw !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.placeholder-dark {
  &::placeholder {
    color: black;
  }
}

.detail-title {
  max-width: 100vw;
  font-size: min(2.5rem, 3vw);

  white-space: nowrap;
  // & .team-name-text {
  //   max-width: 100%;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
}

.unsetButton {
  border: unset;
  background: unset;
  padding: unset;
  margin: unset;
  outline: unset;
}

.flex-1 {
  flex: 1;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.simple-txt-btn {
  border: none;
  background-color: inherit;
  display: inline-block;
}

.simple-txt-btn:hover {
  background: #eee
}

.upload-creatives-wrapper {
  padding-top: 2rem;

  & .upload-creatives-titles {
    display: flex;

    & h5 {
      margin-right: 9rem;
    }
  }

  & .upload-creatives-row {
    display: flex;
    margin: 0.5rem 0;

    & * {
      flex: 1;
    }
  }
}

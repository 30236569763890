.shared-page-wrapper {
  .shared-actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;

    .dco-button.shared-comment-btn {
      background-color: #ffbe00;
    }
  }

  .shared-ads-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3rem;
  }
}

.shared-page-wrapper {
  .shared-navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    height: 50px;
    padding: 0 3rem;
  }
}

.shared-filter-icon-wrapper {
  display: flex;
  align-items: center;
  border: 2px solid #afafaf;
  background-color: #f5f5f5;
  border-radius: 1rem;
  margin: 2px;
  padding: 2px 8px;
}

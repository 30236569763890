.icon {
  width: 16px;
  height: 16px;
  mask-repeat: no-repeat !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-position: center !important;
  -webkit-mask-position: center !important;
  mask-size: contain !important;
  -webkit-mask-size: contain !important;
  display: block;
  background-color: black;

  &.x10 {
    width: 10px;
    height: 10px;
  }

  &.x20 {
    width: 20px;
    height: 20px;
  }

  &.x24 {
    width: 24px;
    height: 24px;
  }

  &.x32 {
    width: 32px;
    height: 32px;
  }

  &.x40 {
    width: 32px;
    height: 32px;
  }

  &.x64 {
    width: 64px;
    height: 64px;
  }

  &.icon-dashboard {
    mask: url('../assets/icons/dashboard.svg');
    -webkit-mask: url('../assets/icons/dashboard.svg');
  }

  &.icon-dashboard-teams {
    mask: url('../assets/icons/Projects.svg');
    -webkit-mask: url('../assets/icons/Projects.svg');
  }

  &.icon-team {
    mask: url('../assets/icons/project.svg');
    -webkit-mask: url('../assets/icons/project.svg');
  }

  &.icon-teams {
    mask: url('../assets/icons/Teams.svg');
    -webkit-mask: url('../assets/icons/Teams.svg');
  }

  &.icon-brand {
    mask: url('../assets/icons/Brands.svg');
    -webkit-mask: url('../assets/icons/Brands.svg');
  }

  &.icon-report {
    mask: url('../assets/icons/report.svg');
    -webkit-mask: url('../assets/icons/report.svg');
  }

  &.icon-smile {
    mask: url('../assets/icons/smile.svg');
    -webkit-mask: url('../assets/icons/smile.svg');
  }

  &.icon-attachment {
    mask: url('../assets/icons/attachment.svg');
    -webkit-mask: url('../assets/icons/attachment.svg');
  }

  &.icon-remove {
    mask: url('../assets/icons/remove.svg');
    -webkit-mask: url('../assets/icons/remove.svg');
  }

  &.icon-delete {
    mask: url('../assets/icons/delete.svg');
    -webkit-mask: url('../assets/icons/delete.svg');
    background-color: red;
  }

  &.icon-delete-gray {
    mask: url('../assets/icons/delete.svg');
    -webkit-mask: url('../assets/icons/delete.svg');
    background-color: #adb5bd;
  }

  &.icon-lock {
    mask: url('../assets/icons/lock.svg');
    -webkit-mask: url('../assets/icons/lock.svg');
    background-color: black;
  }

  &.icon-unlock {
    mask: url('../assets/icons/unlock.svg');
    -webkit-mask: url('../assets/icons/unlock.svg');
    background-color: black;
  }

  &.icon-edit {
    mask: url('../assets/icons/edit.svg');
    -webkit-mask: url('../assets/icons/edit.svg');
  }

  &.icon-favorites-fill {
    mask: url('../assets/icons/favorites-fill.svg');
    -webkit-mask: url('../assets/icons/favorites-fill.svg');
    background-color: #ffbe00;
  }

  &.icon-favorites-no-fill {
    mask: url('../assets/icons/favorites-no-fill.svg');
    -webkit-mask: url('../assets/icons/favorites-no-fill.svg');
  }

  &.icon-arrow-left {
    mask: url('../assets/icons/arrow-left.svg');
    -webkit-mask: url('../assets/icons/arrow-left.svg');
  }

  &.icon-arrow-right {
    mask: url('../assets/icons/arrow-r.svg');
    -webkit-mask: url('../assets/icons/arrow-r.svg');
  }

  &.icon-comment {
    mask: url('../assets/icons/comment.svg');
    -webkit-mask: url('../assets/icons/comment.svg');
  }

  &.icon-filter {
    mask: url('../assets/icons/filter.svg');
    -webkit-mask: url('../assets/icons/filter.svg');
  }

  &.icon-reload {
    mask: url('../assets/icons/reload.svg');
    -webkit-mask: url('../assets/icons/reload.svg');
  }

  &.icon-export-dco-creative {
    background: url('../assets/icons/export.svg');
    width: 63px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-download-dco-creative {
    background: url('../assets/icons/download-dco-creative.svg');
    width: 93px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-view {
    mask: url('../assets/icons/view.svg');
    -webkit-mask: url('../assets/icons/view.svg');
  }

  &.icon-invisible {
    mask: url('../assets/icons/invisible.svg');
    -webkit-mask: url('../assets/icons/invisible.svg');
  }

  &.icon-view-blue {
    mask: url('../assets/icons/view.svg');
    -webkit-mask: url('../assets/icons/view.svg');
    background-color: #081ee0;
  }

  &.icon-invisible-blue {
    mask: url('../assets/icons/invisible.svg');
    -webkit-mask: url('../assets/icons/invisible.svg');
    background-color: #081ee0;
  }

  &.icon-share {
    mask: url('../assets/icons/share.svg');
    -webkit-mask: url('../assets/icons/share.svg');
  }

  &.icon-warning {
    mask: url('../assets/icons/warning.svg');
    -webkit-mask: url('../assets/icons/warning.svg');
  }

  &.icon-search {
    mask: url('../assets/icons/search.svg');
    -webkit-mask: url('../assets/icons/search.svg');
  }

  &.icon-check {
    mask: url('../assets/icons/check.svg');
    -webkit-mask: url('../assets/icons/check.svg');
  }

  &.icon-check-green {
    mask: url('../assets/icons/check-green.svg');
    -webkit-mask: url('../assets/icons/check-green.svg');
    background-color: green;
  }

  &.icon-check-outlined {
    mask: url('../assets/icons/check-outlined.svg');
    -webkit-mask: url('../assets/icons/check-outlined.svg');
  }

  &.icon-undo {
    mask: url('../assets/icons/undoIconic.svg');
    -webkit-mask: url('../assets/icons/undoIconic.svg');
  }

  &.icon-redo {
    mask: url('../assets/icons/redoIconic.svg');
    -webkit-mask: url('../assets/icons/redoIconic.svg');
  }

  &.icon-questionmark {
    mask: url('../assets/icons/questionmark.svg');
    -webkit-mask: url('../assets/icons/questionmark.svg');
  }

  &.icon-info {
    mask: url('../assets/icons/info.svg');
    -webkit-mask: url('../assets/icons/info.svg');
    background-color: #ffbe00;
  }

  &.icon-info-gray {
    mask: url('../assets/icons/info.svg');
    -webkit-mask: url('../assets/icons/info.svg');
    background-color: gray;
  }

  &.icon-exclamation-mark {
    mask: url('../assets/icons/exclamation-mark.svg');
    -webkit-mask: url('../assets/icons/exclamation-mark.svg');
    background-color: red;
  }

  &.icon-brain {
    mask: url('../assets/icons/brain.svg');
    -webkit-mask: url('../assets/icons/brain.svg');
  }

  &.icon-chain {
    mask: url('../assets/icons/chain.svg');
    -webkit-mask: url('../assets/icons/chain.svg');
  }

  &.icon-target {
    mask: url('../assets/icons/target.svg');
    -webkit-mask: url('../assets/icons/target.svg');
  }

  &.icon-restart-plain {
    mask: url('../assets/icons/restart-plain.svg');
    -webkit-mask: url('../assets/icons/restart-plain.svg');
  }

  &.icon-zoom {
    mask: url('../assets/icons/zoom.svg');
    -webkit-mask: url('../assets/icons/zoom.svg');
  }

  &.icon-star {
    mask: url('../assets/icons/star.svg');
    -webkit-mask: url('../assets/icons/star.svg');
    background-image: linear-gradient(40deg,
        hsl(36deg 80% 46%) 0%,
        hsl(38deg 83% 47%) 11%,
        hsl(40deg 86% 47%) 22%,
        hsl(42deg 90% 48%) 33%,
        hsl(45deg 94% 48%) 44%,
        hsl(47deg 98% 48%) 56%,
        hsl(49deg 100% 48%) 67%,
        hsl(51deg 100% 49%) 78%,
        hsl(53deg 100% 50%) 89%,
        hsl(55deg 100% 50%) 100%);
  }

  &.icon-document {
    mask: url('../assets/icons/document.svg');
    -webkit-mask: url('../assets/icons/document.svg');
  }

  &.icon-new-window {
    mask: url('../assets/icons/new-window.svg');
    -webkit-mask: url('../assets/icons/new-window.svg');
  }

  &.icon-cancel-transparent {
    mask: url('../assets/icons/cancel.svg');
    -webkit-mask: url('../assets/icons/cancel.svg');
  }

  &.icon-hamburger {
    mask: url('../assets/icons/hamburger.svg');
    -webkit-mask: url('../assets/icons/hamburger.svg');
  }

  &.icon-x {
    mask: url('../assets/icons/x.svg');
    -webkit-mask: url('../assets/icons/x.svg');
  }

  &.icon-remove-o {
    background-color: transparent;
    background: url('../assets/icons/remove.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-plus-blue {
    background-color: transparent;
    background: url('../assets/icons/circle-with-plus.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-google {
    background-color: transparent;
    background: url('../assets/icons/google.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-FB {
    background-color: transparent;
    background: url('../assets/icons/FB.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-circle-with-cross {
    background-color: transparent;
    background: url('../assets/icons/circle-with-cross.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-folder-black {
    background-color: transparent;
    background: url('../assets/icons/folder-black.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-folder-blue {
    background-color: transparent;
    background: url('../assets/icons/folder-blue.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-creative-set {
    background-color: transparent;
    background: url('../assets/icons/creative-set.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-download-blue {
    background-color: transparent;
    background: url('../assets/icons/download-blue.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-download-gray {
    background-color: transparent;
    background: url('../assets/icons/download-gray.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-eye-blue {
    background-color: transparent;
    background: url('../assets/icons/eye.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-eye-warning {
    background-color: transparent;
    background: url('../assets/icons/eye-warning.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-document-outline {
    background-color: transparent;
    background: url('../assets/icons/document-outline.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-cancel {
    background-color: transparent;
    background: url('../assets/icons/cancel.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(45%) sepia(65%) saturate(695%) hue-rotate(149deg) brightness(99%) contrast(108%);
  }

  &.icon-down {
    background-color: transparent;
    background: url('../assets/icons/Down.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(45%) sepia(65%) saturate(695%) hue-rotate(149deg) brightness(99%) contrast(108%);
  }

  &.icon-up {
    background-color: transparent;
    background: url('../assets/icons/Up.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(45%) sepia(65%) saturate(695%) hue-rotate(149deg) brightness(99%) contrast(108%);
  }

  &.icon-minus {
    background-color: transparent;
    background: url('../assets/icons/Minus.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(45%) sepia(65%) saturate(695%) hue-rotate(149deg) brightness(99%) contrast(108%);
  }

  &.icon-plus {
    background-color: transparent;
    background: url('../assets/icons/plus.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(45%) sepia(65%) saturate(695%) hue-rotate(149deg) brightness(99%) contrast(108%);
  }

  &.icon-plus-gray {
    background-color: $gray-600;
    mask: url('../assets/icons/plus.svg');
    -webkit-mask: url('../assets/icons/plus.svg');
  }

  &.icon-flip {
    background-color: transparent;
    background: url('../assets/icons/flip.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(45%) sepia(65%) saturate(695%) hue-rotate(149deg) brightness(99%) contrast(108%);
  }

  &.icon-restart {
    background-color: transparent;
    background: url('../assets/icons/Restart.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(45%) sepia(65%) saturate(695%) hue-rotate(149deg) brightness(99%) contrast(108%);
  }

  &.icon-hover:hover {
    filter: invert(92%) sepia(40%) saturate(2566%) hue-rotate(348deg) brightness(109%) contrast(109%);
  }
}

.loader-button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.375rem 2rem;
  min-height: 36px;
  height: fit-content;
  margin: 1px;
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: larger;
  border: 0;
  font-weight: bold;
  background-color: $primary;
  color: $white;
  border-radius: 1rem;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

.loader-button.white {
  background-color: $white;
}
.loader-button.gray {
  background-color: $gray-600;
}
.loader-button.primary {
  background-color: $primary;
}
.loader-button.completed {
  background-color: $success;
}
.loader-button.error {
  background-color: $danger;
}

.loader-button.active {
  opacity: 0.5;
  cursor: not-allowed;
}
.loader-button:focus {
  box-shadow: 0 0 0 0.5rem rgba(0, 123, 255, 0.25);
}
.loader-button.active .loader-button-text {
  margin-right: 2rem;
}
.loader-button.active .loader-button-loader {
  animation: spin 0.25s linear infinite;
  opacity: 1;
}
.loader-button .loader-button-text {
  transition: margin-right 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-right: 0;
  display: flex;
  align-items: center;
}
.loader-button .loader-button-loader {
  position: absolute;
  right: 1.25rem;
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
  border-left: 2px solid $white;
  border-top: 2px solid $white;
  border-radius: 1000px;
  opacity: 0;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.brainsight {
  box-shadow: 0.5px 2.5px 5px $gray-600, -2.5px -2.5px 5px $white;
  color: $primary !important;
  .icon-div {
    border: 2px solid rgb(254, 202, 87);
    border-radius: 50%;
    background-color: rgb(254, 202, 87);
    box-shadow: 0 0 0 7px rgb(254 202 87 / 60%);
  }
}

.video-converter-btn {
  box-shadow: 0.5px 2.5px 5px #afafaf, -2.5px -2.5px 5px #ffffff;
  width: 190px;

  &:focus {
    border: 2px solid rgb(230, 128, 128);
  }
}

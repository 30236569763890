.file-asset {
  & .file-asset-tabs {
    // margin-bottom: 13px;
    & .tab-item {
      color: $gray-500;
      margin-right: 20px;
      cursor: pointer;
    }
    &.filtes {
      margin-top: 25px;
    }
  }

  .file-asset-breadcrumb {
    margin-bottom: 10px;
    display: flex;
    & .breadcrumb-i {
      color: $primary;
      cursor: pointer;
      &:last-of-type {
        &::before {
          padding: 0 10px;
          content: '\00BB';
          color: #000000;
        }
      }
      &::before {
        padding: 0 10px;
        content: '\003e';
      }

      &.active {
        color: #000000;
        cursor: default;
      }
    }
  }

  & .assets {
    max-height: 600px;
    overflow-y: auto;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, 80px);
    // column-gap: 20px;
    // row-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    & > div {
      margin: 10px;
    }
    & .thumb {
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1px solid #e7e7e7;
      border-radius: 2px;
      position: relative;

      &.selected {
        border: 2px solid #feca57;
        background-color: rgba(#feca57, 0.1);
        & img {
          padding: 3px;
        }
        & .checkbox {
          position: absolute;
          right: 2px;
          top: 2px;
          opacity: 1;
        }
      }
      & .icon {
        width: 100%;
        height: 100%;
      }
      & .version {
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 0.4rem !important;
      }
      & img {
        border-radius: 2px;
        max-width: 80px;
        max-height: 80px;
        padding: 1px;
      }
      & .checkbox {
        position: absolute;
        right: 2px;
        top: 2px;
        opacity: 0;

        & label {
          color: #777;
          display: inline-block;
        }
        & input[type='radio'] {
          display: none;
        }
        & input[type='radio']:checked + label span.small {
          background-color: rgba(255, 190, 0, 1);
          opacity: 1;
        }
        input[type='radio']:checked + label.circle span.small {
          background-color: #ffbe00;
          margin-left: -4px;
          margin-top: -4px;
          width: 8px;
          height: 8px;
        }

        & .circle .big {
          height: 8px;
          width: 8px;
          padding: 4px;
          border: 2px solid white;
        }

        & span.big {
          background-color: white;
          border-radius: 50% 50% 50% 50%;
          display: inline-block;
          height: 12px;
          margin-bottom: -2px;
          padding: 8px;
          position: relative;
          top: -3px;
          width: 12px;
        }
        & span.small {
          border-radius: 50% 50% 50% 50%;
          display: block;
          height: 100%;
          transition: background 0.4s ease 0s;
          width: 100%;
        }
      }
    }

    & .thumb:hover {
      & .checkbox {
        position: absolute;
        right: 2px;
        top: 2px;
        opacity: 1;
      }
    }

    & .folder {
      border: none;
      background: url('../assets/icons/folder.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      &.selected {
        background: url('../assets/icons/folder-selected.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        background-color: transparent;
      }
    }

    & .name {
      margin-top: 12px;
      font-size: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
      // white-space: nowrap;
      -webkit-line-clamp: 2;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}

.rootContainer {
  --myFill: linear-gradient(#d00, #d00);
  --myTop: 0px;
  --myLeft: 0px;
  --myBottom: 0px;
  --myRight: 0px;
  --myZoom: 1;
  --myScale: 1;
  --myHeight: 50px;
  width: 100%;
  outline: revert !important;
}
/* .rootContainer :not(#previews) {
  z-index: 1;
} */

.image {
  image-rendering: high-quality;
}

.overflow-auto {
  overflow: auto !important;
}

.pic {
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.rect {
  border: 2px solid #ffbe00;
  left: -1000px;
  position: absolute;
  top: -1000px;
  display: 'none';
}

#myImg {
  clip-path: inset(
    calc(var(--myZoom) * var(--myTop)) calc(var(--myZoom) * var(--myRight))
      calc(var(--myZoom) * var(--myBottom)) calc(var(--myZoom) * var(--myLeft))
  );
}

#res {
  text-align: center;
  color: #eee;
  position: absolute;
  font-size: 13px;
  display: none;
}

.render {
  font-size: 30px;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  pointer-events: none;
  color: #ffbe00;
  text-shadow: 0 0 5px black;
}
.filesize {
  position: absolute;
  left: 7px;
  bottom: 7px;
  pointer-events: none;
  color: #ffbe00;
  text-shadow: 0px 0px 2px #000, 0px 0px 2px #000;
}
#fPoint {
  position: absolute;
  display: flex;
  justify-content: center;

  text-align: center;
  vertical-align: middle;
  left: -100px;
  border-radius: 100%;
  padding: 5px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 9;
}

#fPoint > * {
  pointer-events: none;
}

.fCenter {
  color: blue;
  background-color: #fff8;
}

.fMove {
  color: blue;
  background-color: #fff8;
}

#myVideo {
  position: relative;
  -webkit-border-radius: 1px;
  max-height: 350px;
  max-width: 100%;
  object-fit: contain;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

#masker {
  z-index: 1;
  position: absolute;
}

#bg {
  pointer-events: none;
  opacity: 50%;
  width: 100%;
  height: 100%;
  background: var(--myFill) left / var(--myLeft) 100%, var(--myFill) top / 100% var(--myTop),
    var(--myFill) right / var(--myRight) 100%, var(--myFill) bottom / 100% var(--myBottom);
  background-repeat: no-repeat;
}
.resizable {
  position: absolute;
  width: 0%;
  height: 0%;
  top: 0px;
  left: -100px;
  z-index: 1;
}

.resizable .resizers {
  width: 100%;
  height: 100%;

  border: 3px solid #4286f4;
  box-sizing: border-box;
}

.resizable .resizers .resizer {
  width: 20px;
  height: 20px;
  background: transparent;
  position: absolute;
  box-sizing: border-box;
}

.resizable .resizers .resizer.top-left {
  left: -2px;
  top: -2px;

  border-left: 3px solid #4286f4;
  border-top: 3px solid #4286f4;
  cursor: nwse-resize; /*resizer cursor*/
}

.resizable .resizers .resizer.top-right {
  right: -2px;
  top: -2px;
  border-right: 3px solid #4286f4;
  border-top: 3px solid #4286f4;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
  left: -2px;
  bottom: -2px;
  border-left: 3px solid #4286f4;
  border-bottom: 3px solid #4286f4;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
  right: -2px;
  bottom: -2px;
  border-right: 3px solid #4286f4;
  border-bottom: 3px solid #4286f4;
  cursor: nwse-resize;
}

#previews {
  position: relative;
  width: 90%;
  display: flex;
  flex-flow: wrap;
  margin: auto;
  margin-bottom: 100px;
  gap: 25px min(2.2vw, 25px);
  justify-content: center;
  z-index: 0;
}

#preview {
  position: relative;
  display: inline-block;
  border: 1px solid white;
  outline: 2px solid #ddd !important;
  white-space: nowrap;
  z-index: 1;
  font-size: 14px;
  margin-bottom: 20px;
}

#preview,
#thumb,
#myImg {
  box-sizing: content-box !important;
}

#preview.drop {
  outline: 3px solid #f7b500 !important;
}

#preview:is(.drag, .aktief, :hover) {
  border: 1px solid white;
  outline: 2px solid blue !important;
  z-index: 0;
}

#preview:is(.drag, .aktief, :hover) > #thumb {
  overflow: visible;
  outline-style: solid !important;
  outline-width: max(100vw, 100vh) !important;
  /* animation: 1s ease-out 0.1s forwards fader; */
}

#preview.dragging > #thumb {
  overflow: hidden !important;
  outline-style: none !important;
  outline-width: 0px !important;
}

@keyframes fader {
  from {
    outline-color: #ffffff90 !important;
  }
  to {
    outline-color: #ffffff99 !important;
  }
}

#preview .footer {
  margin: 0px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  font-size: small;
  margin-top: 5px;
  color: #e7e7e7;

  cursor: grab;
}

:is(.aktief, #preview:hover) > .footer {
  width: calc(100% - 68.5px);
}
#preview .header {
  margin: 0px;
  height: 20px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  position: absolute;
  top: -20px;
  color: #71716f;
  /* cursor: grab; */
}

:is(.aktief, #preview:hover) > .header {
  width: calc(100% - 68.5px);
}

#thumb {
  position: relative;
  cursor: move;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -10 !important;
  outline-color: #ffffff90 !important;
}

#thumb > #myImg {
  pointer-events: none;
  z-index: -1;
}

#preview > i.fa {
  display: none;
}

:is(.aktief, #preview:hover) > input {
  display: inline-block;
}

:is(.aktief, #preview:hover) > i.fa {
  display: inline-block;
}

#preview > input {
  padding-right: 0px;
  top: -2.5px;
  position: relative;
  margin-left: 3px;
  margin-bottom: 10px;
  display: none;
  max-width: 44px;
  max-height: 16px;

  font-size: 10px;
  z-index: 2;
  border: 2px solid #777;
}

body:is(.zoom, .move) #preview {
  pointer-events: none !important;
}

.fa-search-plus {
  color: blue;
  position: relative;
  margin-left: min(5px, calc(100% - 63px));
  cursor: ns-resize;
}

.aktief > .fa-undo {
  display: none !important;
}

.zoom {
  cursor: ns-resize !important;
}

.move {
  cursor: move !important;
}

.fa-undo {
  position: relative;
  background-color: white;
  cursor: pointer;

  width: 1em;
  text-align: center;
  border-radius: 2px;
}

#myContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5;
  outline: 20px solid #f5f5f5 !important;
  margin: 20px 0px;
  z-index: 2;
}

#myHolder {
  margin: auto 0px;
  max-width: calc(90% - 110px);
  z-index: 2;
}

.fa-times {
  display: none;
  margin: 1.5px;
  float: right;
  color: darkred;
}

.fa-times:hover {
  color: red;
}

.hidden {
  display: none !important;
}
.hidden > * {
  display: none;
}

.drag > #preview > * {
  pointer-events: none;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  pointer-events: none;
}

.overlayButton:disabled {
  background-color: #afafaf !important;
  cursor: default !important;
}

.video-templates-upload-form {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2rem;
  margin: 2rem 0;
}

.video-templates-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

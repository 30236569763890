.brand-teams-list {
  display: flex;
  align-items: center;
  gap: 2rem;
  row-gap: 2.5rem;
  flex-wrap: wrap;

  a {
    width: 200px;
    aspect-ratio: 1;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.dco-setup-thumbnail-wrapper {
  width: 160px;
  margin: 1rem 2rem 1rem 0;
  overflow: hidden;
  position: relative;

  .dco-setup-thumbnail {
    height: 160px;
    background-image: url('../../assets/images/setup_creative2.svg');
    background-size: cover;
    cursor: pointer;
  }

  p {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: rgb(131, 35, 35);
    color: white;
    height: 1rem;
    aspect-ratio: 1;
    border-radius: 50%;
    font-weight: 900;
  }

  .setup-thumbnail-hover {
    &:hover {
      color: $black;
      cursor: pointer;
    }
  }

  h5 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    min-height: 3rem;
    max-width: fit-content;
    word-break: break-all;
    font-size: 0.75rem;
  }
}

.ad-columns-wrapper {
  padding-top: 2rem;
  & .ad-columns-title {
    font-weight: 900;
  }

  & .ad-columns-list {
    & .ad-column-row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin: 0.5rem 0;
      height: 50px;
      gap: 1rem;

      .ad-columns-row-box {
        width: 190px;
      }

      .circle-minus-svg {
        height: 20px;
        width: 20px;
        background-image: url('../../assets/icons/circle-minus-solid.svg');
        background-repeat: no-repeat;
        margin: 0 5px 5px 0;
      }

      .custom-ad-columns-title-wrapper {
        width: 250px;
        position: relative;
      }

      .custom-label-input {
        width: 100%;
        color: gray;
        border: none;
        border-bottom: 2px solid rgb(111, 100, 100);
        flex: 1;
        padding-bottom: 0.5rem;
        margin-bottom: 0;
      }

      .custom-label-check {
        position: absolute;
        right: 10px;
        bottom: 12px;
        background-image: url('../../assets/icons/check.svg');
        background-size: cover;
        width: 12px;
        height: 12px;
        cursor: pointer;

        &.select-custom {
          right: -20px;
        }
      }

      & h5 {
        color: gray;
        border-bottom: 2px solid rgb(111, 100, 100);
        flex: 1;
        padding-bottom: 0.5rem;
        margin-bottom: 0;
      }

      .no-edit {
        cursor: not-allowed;
      }

      & .ad-column-dropdown {
        flex: 1;
        padding: 0 0.5rem;
      }

      & .ad-column-input {
        flex: 1;
        padding: 0 0.5rem;
        height: 80%;
      }

      .ad-column-boolean-wrapper {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        flex: 1;
        height: 100%;

        input[type='checkbox'] {
          margin-right: 10px;
          margin-bottom: 12px;
          padding: 5px;
        }
      }

      .ad-column-size-wrapper {
        height: 100%;
        display: flex;
        align-items: flex-end;
        flex: 1;

        input {
          width: 100px;
        }

        h2 {
          margin: 0 8px 8px 10px;
        }
      }
    }
  }
}

@import '~sass-rem/_rem.scss';

@font-face {
  font-family: 'Biennale';
  src: url('../assets/fonts/Biennale Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Biennale';
  src: url('../assets/fonts/Biennale Medium.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Biennale';
  src: url('../assets/fonts/Biennale Bold.otf') format('opentype');
  font-weight: 700;
}

$gray-900: #4a4a4a;
$gray-600: #afafaf;
$gray-200: #f5f5f5;

// $blue:    #0147D5;
// $red:     #FF6B6B;
// $green:   #1DD1A1;
// $cyan:    #E8F9FF;
// $orange:  #FDBB42;

$white: #ffffff;

$secondary: #eaeff1;
$primary: #081ee0;
$success: #1dd1a1;
$info: #feca57;
$warning: #fecec6;
$danger: #ff6b6b;

$enable-shadows: false;

$body-bg: $white;
$body-color: #000000;

$link-hover-decoration: none;

$border-color: $secondary;

$border-radius: rem(5px);
$border-radius-lg: rem(5px);
$border-radius-sm: rem(2px);

$font-size-base: rem(12px);

$font-family-sans-serif: 'Biennale';
$font-family-monospace: 'Biennale';
$font-family-base: $font-family-sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: rem(40px);
$h2-font-size: rem(24px);
$h3-font-size: rem(20px);
$h4-font-size: rem(16px);
$h5-font-size: rem(14px);
$h6-font-size: rem(12px);

$hr-border-color: $border-color;

$table-hover-bg: #fdfffe;
$table-active-bg: #fdfffe;

$input-bg: #fdfffe;

$input-color: #000000;
$input-border-color: $secondary;
$input-box-shadow: none;

$input-focus-bg: $input-bg;
$input-focus-border-color: $border-color;
$input-focus-color: $input-color;
$input-focus-box-shadow: none;

$input-group-addon-bg: $input-bg;

$tooltip-bg: $info;
$tooltip-color: $gray-900;

$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-box-shadow: none;

$alert-padding-y: rem(5px);
$alert-padding-x: rem(10px);
$alert-border-radius: 0;

$badge-font-weight: 700;
$badge-border-radius: rem(2px);

$tooltip-bg: $secondary;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;
$tooltip-padding-y: rem(5px);
$tooltip-padding-x: rem(5px);

.dco-creative-size-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 35px;

  p {
    margin: 0;

    &:hover {
      cursor: pointer;
    }

    &.dco-creative-title {
      width: 75px;

      &:hover {
        cursor: initial;
      }
    }
  }
}

.html-templates-upload-form {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  padding-bottom: 2rem;

  .html-templates-form-group {
    display: flex;
    align-items: flex-end;
    gap: 1rem;

    .size-width {
      max-width: 100px;
    }
  }
}

.html-templates-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 4rem;
}

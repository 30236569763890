.dco-title {
  display: flex;
  align-items: center;
}
.dco-title h1 {
  margin: 0px;
}
.dco-subtitle {
  margin-left: 2rem;
}

.dco-header {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  border-bottom: 1px solid $secondary;
}

.dco-header .tab-content {
  display: none;
}

.preview-wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 2rem;
  width: 100%;
  overflow: hidden;

  .preview-banner {
    p {
      margin: 0;
      color: var(--gray-60-color);
    }
  }
}
.ad-preview-wrapper {
  border-bottom: 2px solid $gray-600;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.dco-tabs .tab-content {
  padding-top: 2rem;
}
.dco-tabs-small .tab-content {
  padding-top: 0.5rem;
}

.dco-button {
  letter-spacing: 1px;
  font-weight: bold;
  border: none;
  font-size: larger;
  color: $white;
  height: 35px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 2rem;
  line-height: 1.5;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: $gray-900;
  &:hover {
    background-color: $gray-600;
  }
  &.info {
    background-color: $info;
  }
  &.success {
    background-color: $success;
  }
  &.primary {
    background-color: $primary;
  }
  &.danger {
    background-color: $danger;
  }
  &.disabled {
    background-color: $gray-600;
  }
  &.gray {
    background-color: $gray-200;
  }
}

.dco-select {
  color: $gray-600;
  text-align: center;
  border: none;
  background-color: $gray-200;
  width: 5rem;
}

.filter-tabs {
  border: none !important;
  display: flex;
  align-items: flex-end;
  margin-top: 1rem;
  margin-bottom: 1px;
  padding: 0 0.5rem;
}

.filter-tabs .nav-link {
  background-color: $gray-600;
  color: $white !important;
  border-radius: 0px;
  padding: 0.5rem 1.5rem;
  &.active {
    background-color: $primary;
    padding-top: 0.75rem;
  }
}

.filter li:first-child {
  margin-right: 0.5rem;
}

.dco-project-tabs .nav-item {
  padding-right: 2rem;
}

.dco-edit-input {
  height: calc(1.5em + 0.5rem + 2px);
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $primary;
}
.dco-edit-label {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  color: $gray-600;
}

.dco-table-title-cell {
  color: $primary;
  font-weight: 700;
  &.hoverable {
    &:hover {
      text-decoration: underline;
    }
  }
}

.dco-modal-field {
  font-size: 2.5rem;
}

.primary-text {
  color: $primary;
}
.primary-background {
  background-color: $primary;
}

.danger-text {
  color: $danger;
}
.danger-background {
  background-color: $danger;
}
.secondary-text {
  color: $secondary;
}

.success-text {
  color: $success;
}
.success-background {
  background-color: $success;
}
.info-text {
  color: $info;
}
.info-background {
  background-color: $info;
}
.warning-text {
  color: $warning;
}
.warning-background {
  background-color: $warning;
}
.gray-600-text {
  color: $gray-600;
}
.gray-600-background {
  background-color: $gray-600 !important;
}

.gray-200-background {
  background-color: $gray-200;
}
.gray-200-text {
  color: $gray-200;
}
.disabled-text {
  color: #adb5bd;
}

.white-background {
  background-color: $white;
}
.dco-search {
  background-color: $white;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $primary;
}

.dco-filters-header button {
  display: flex;
  border: none;
  font-size: 1rem;
  background-color: $gray-200;
  align-items: center;
}

.dco-filters-header-icon {
  background-color: $primary;
}

.dco-filter {
  display: flex;
  min-width: 250px;
  background-color: $white;
  flex-direction: column;
  text-align: left;
  margin: 1rem;
}

.dco-undo {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: $primary;
  cursor: pointer;
  > .icon {
    background-color: $primary;
  }
  &.disabled {
    color: $gray-600;
    cursor: default;
    > .icon {
      background-color: $gray-600;
    }
  }
}

.mw-10 {
  min-width: 10rem;
}

.dco-review-gradient {
  height: 4px;
  background: linear-gradient(90deg, #f7b500 35%, #1dd1a1 65%);
  width: 100%;
  min-width: 100%;
  display: block;
  margin-bottom: 2rem;
}
.dco-publish-gradient {
  height: 4px;
  background: linear-gradient(90deg, #1dd1a1 35%, #0925de 65%);
  width: 100%;
  min-width: 100%;
  display: block;
  margin-bottom: 2rem;
}

.publish-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.icon-button-disabled {
  color: $gray-600;
  > .icon {
    background-color: $gray-600;
  }
}

.opacity-25 {
  opacity: 0.25 !important;
}

.info-border {
  border: 2px solid $info;
}

.accordion-header button {
  display: flex;
  border: none;
  border-left: 1px solid $primary;
  font-size: 1rem;
  width: 300px;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0 1rem 1rem 0;
}

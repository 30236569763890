.switch input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch p {
  cursor: pointer;
  margin: 0px 1rem 0px 0px;
  padding-right: 1rem;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-radius: 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 18px;
    transition: 0.3s;
  }
}

.switch input:checked + p {
  background: $primary;
}

.switch input:checked + p:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.switch p:active:after {
  width: 26px;
}

.switch {
  display: flex;
  align-items: end;
}

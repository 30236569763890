@import './creativeShotRow';

.creative-variables-wrapper {
  padding-top: 2rem;

  & .creative-column-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h3 {
      font-weight: 900;
    }
  }

  & .creative-row-wrapper {
    display: flex;
    align-items: flex-end;
    margin: 0.5rem 0;
    height: 50px;
    gap: 1rem;

    .creative-row-box {
      width: 250px;
    }

    .custom-creative-variables-title-wrapper {
      width: 250px;
      position: relative;
    }
    & h5 {
      color: gray;
      border-bottom: 2px solid rgb(111, 100, 100);
      margin: 0;
      padding-bottom: 0.5rem;
    }

    .creative-default-values {
      width: 100%;
      height: 38px;
      padding-left: 0.5rem;
    }
  }

  .creative-shot-images {
    padding-top: 2rem;
  }
}

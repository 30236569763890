.upload-creatives-form {
  margin: 1rem 0;
  .upload-creatives-form-title {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  h3 {
    margin: 0;
  }
}

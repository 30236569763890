.project-info-wrapper {
  .project-info-group {
    margin: 2rem 0;

    input {
      border: none;
      border-bottom: 2px solid darkblue;
      min-width: 300px;
    }
  }
}

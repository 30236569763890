.dco-input {
  height: calc(1.5em + 0.5rem + 2px);
  border-radius: 0;
  width: 100%;
}

.dco-accordion {
  padding: 0.25rem 0.5rem;
  background-color: white;
  margin-bottom: 1rem;
}
.dco-accordion-header {
  font-size: 0.65625rem;
  line-height: 1.5;
  margin: 0px;
}

.dco-accordion-header button {
  border: none;
  background-color: white;
  width: 100%;
  text-align: start;
  padding: 0px;
}

.dco-accordion-body {
  border-top: 1px solid $gray-200;
  margin-top: 0.25rem !important;
  padding-top: 0.5rem !important;
}

.dco-pagination {
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.dco-pagination select {
  max-width: 150px;
}

.dco-filter-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  background-color: $gray-200;
  border-bottom: 3px solid $gray-600;
}
